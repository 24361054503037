.ant-drawer.ant-drawer-right.add_project {
  .css-2b097c-container {
    margin-top: 10px;
  }
  .css-yk16xz-control {
    height: 40px;
    border-radius: 2px;
  }
  .input--field {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    height: 40px;
    font-size: 14px;
  }
}

.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.select--field {
  height: 40px !important;
  font-size: 16px !important;
  width: 90% !important;
  margin-top: 3px !important;
  margin-bottom: 6px !important;
  background-color: #fff;
  padding-right: 200px;
}

.select-before {
  width: 90px;
}

[data-theme="compact"] .select-before {
  width: 71px;
}

.mb--10 {
  margin-bottom: 1rem !important;
}

.sales-primary-btn {
  background-color: #5c67e5;
  margin-top: 2%;
  width: 16%;
  border-radius: 3px;
}

.sales-primary-btn:hover {
  background-color: #5c67e5;
}

.PO-upload {
  margin-bottom: 2%;
}

.label .sales_title {
  margin-bottom: 20% !important;
}

.icon-hover {
  cursor: pointer;
}

.display-vertical-center {
  display: flex;
  align-items: center;
}
