.custom-table thead th {
  background-color: #f6f7fb;
  color: #485359 !important;
}

.project-code-column,
.project-name-column {
  background-color: #f6f7fb;
}

.project-code-column,
.project-name-column {
  background-color: #f6f7fb;
}

.searchForm .ant-input-group-wrapper .ant-input-affix-wrapper .ant-input {
  height: 30px !important;
  margin: -1px 0 0 0;
  padding: 0 0 0 25px;
}

.fixed-header thead th { /* the thead selector is required because there are th elements in the body */
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}
.div-padding{
  padding-top: 5px;
  padding-left: 20px ;
}
.year-dropdown {
    padding-left: 10px;
    padding-top: 5px;
    width: auto !important;
}
.header-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-div-child {
  margin-bottom: 8px;
  font-size: 16px;
}

.header-week-range {
  font-size: 12px;
  color: #8595a5;
  background: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px 4px 10px;
}

.left-right-navigation-button {
  padding: 0px 0px 20px 15px;
}

.audit-buttons {
  width: 250px;
  height: 40px;
  border-radius: 4px;
  background-color: #5c67e5;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audit-button-view {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.audit-wsr-search{
    padding-top: 20px;
}

.cancel-button{
  background-color: white;
  color: black;
  height: 40px;
  width: 115px;
  border-color: black;
  border-radius: 4px;
  transition: background-color 0.3s; 
}

.cancel-button:hover {
  background-color: white; 
  color: black;
  border-color: black;
}