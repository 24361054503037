.ant-layout-sider {
  .type1 {
    display: block;
    padding: 40px 0 25px 0;
    text-align: center;
  }
  .type2 {
    display: none;
    padding: 30px 0 30px 0;
    text-align: center;
  }
  .logo1 {
    display: block;
  }
  .logo2 {
    display: none;
  }
}

.ant-layout-sider-collapsed {
  .type1 {
    display: none;
    padding: 40px 0 25px 0;
    text-align: center;
  }
  .type2 {
    display: block;
    padding: 30px 0 30px 0;
    text-align: center;
    img {
      width: 40%;
    }
  }
  .logo1 {
    display: none;
  }
  .logo2 {
    display: block;
    width: 27px;
    height: 28px;
    margin: 0 auto;
    img {
      width: 40%;
    }
  }
}
