.ant-drawer.ant-drawer-right.manage_talent {
  .css-2b097c-container {
    margin-top: 10px;
  }

  .css-yk16xz-control {
    height: 40px;
    border-radius: 2px;
    width: 93%;
  }

  .select--field {
    margin-top: 4px;
    margin-bottom: 6px;
    width: fit-content;
  }
}


.button-container {
  margin-top: 20px;

  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

.percent {
  margin: 0 5px;
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.edit_Loader {
  width: max-content !important;
  left: 23% !important;
}
