.ant-drawer.ant-drawer-right.add_project {
  .css-2b097c-container {
    margin-top: 10px;
    height: 100%;
  }
  .css-yk16xz-control {
    height: 100%;
    border-radius: 2px;
    width: auto;
    position: relative;
  }
}

.button-container {
  margin-top: 20px;
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.ant-switch {
  background-color: red;
}

.ant-switch-checked {
  background-color: green;
}

.Spacing{
  margin-top: 1.3rem !important;
}

.disabled-button {
  background-color: #f5f5f5 !important;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  height: 40px;
  color:grey !important;
  background:#f5f5f5 !important;
  &:hover {
    background: #f5f5f5 !important;
    color:grey !important;
  }
}