.assistant {
  width: 700px;
  height: 700px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(12, 6, 43, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
}

.main-container {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.msg-input {
  flex-grow: 2;
  margin-right: 10px;
  background-color: #f6f7fb;
  line-height: 50px;
  border-radius: 15px;
  width: 680px;
  font-size: 14px;
  flex: 1;
  /* position: fixed; */
}
.cs-message-input__content-editor{
  font-size: 18px;
  line-height: 35px;
  padding: 0 10px;
}



.msg-input,
.submit-button,
.clear-button {
  margin-top: 5px;
  position: relative;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: white; 
}
::-webkit-scrollbar {
  width: 5px;
}
.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
}

.InputToolbox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  box-sizing: border-box;
  align-items: center;
}

.messageContainer {
  max-height: 600px;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.messageContainer .Message {
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  width: auto;
  max-width: 80%;
}

.messageContainer .user-message {
  background-color: #698bec;
  color: white;
  align-self: flex-end;
  margin-right: 14px;
}

.messageContainer .ai-message {
  background-color: #eaeaea;
  color: black;
  align-self: flex-start;
  margin-left: 14px;
}

.messageContainer .Message.Header {
  font-size: 0.85em;
  margin-bottom: 4px;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-positive {
  background-color: green;
}
.button-negative {
  background-color: red;
}

.submit-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-left: "8px";
  font-size: 28px;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #838fec;
}
.clear-button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 28px;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #838fec;
}

.submit-button:hover {
  background-color: #a641e9;
}
.clear-button:hover {
  background-color: #a641e9;
}
.submit-button:active {
  transform: scale(0.98);
}
.clear-button:active {
  transform: scale(0.98);
}
.line {
  font-size: 30px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -25px;
  border: "none";
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.line:hover {
  background-color: #838fec;
}
.line:active {
  transform: scale(0.98);
}
.pie {
  font-size: 30px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -25px;
  border: "none";
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.pie:hover {
  background-color: #838fec;
}
.pie:active {
  transform: scale(0.98);
}
.bar {
  font-size: 30px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -25px;
  border: "none";
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.bar:hover {
  background-color: #838fec;
}
.bar:active {
  transform: scale(0.98);
}

.cs-message__content {
  margin-left: 45px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.suggested-questions {
  padding: 10px 20px;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #838fec;
}
.suggested-questions:hover {
  background-color: #a641e9;
}
.suggested-questions:active {
  transform: scale(0.98);
}
.Table {
  font-size: 30px;
  margin-left: 25px;
  margin-top: 20px;
  margin-bottom: -25px;
  border: "none";
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.Table:hover {
  background-color: #838fec;
}
.Table:active {
  transform: scale(0.98);
}
.cs-message-input__content-editor:empty:not(:focus):before {
	content: attr(data-placeholder);
  color: #999999;
}