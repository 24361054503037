.poEditButton {
  cursor: pointer;
}

.totalClaimed {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.RevenueProgress {
  margin-bottom: 25px;
}

.earnswitch {
  margin-left: 10px;
}

.ant-progress-inner {
  height: 15px;
  background-color: #fff;
  border: 1px solid #8595a5;
  .ant-progress-bg {
    height: 100% !important;
    background: rgba(92, 103, 229, 0.5);
  }
}

.editPOImage {
  cursor: pointer;
}

.financialMonth {
  margin-bottom: 0.5rem;
}

.edit-image-button {
  margin-bottom: 40px;
  border: 1px solid #1e2225;
}

.po-button-container {
  margin-bottom: 20px;
  .update-po-button {
    background-color: #5c67e5;
    margin-right: 20px;
  }
  .cancel-button {
    background: #fff;
    color: #1e2225;
    border: 1px solid #1e2225;
  }
  .delete-button {
    margin-left: 20px;
    color: #ef4646;
    border: 1px solid #ef4646;
    background: #fff;
  }
}
.deleteModalMain {
  .modal-deleteBtn,
  .modal-deleteCancelBtn {
    width: 60%;
    display: block;
    margin: 30px auto 15px;
  }
  .modal-deleteBtn {
    border: 1px solid #ef4646;
    color: #ef4646;
  }
  .modal-deleteCancelBtn {
    border: 1px solid #000;
  }
  display: block;
  z-index: 1;
}

.upload-attachment-button {
  background-color: #5c67e5;
  color: #fff;
}

.input--field {
  width: 90% !important;
}

.button-container {
  margin-bottom: 20px;
  margin-right: 40rem;
  display: flex;
  justify-content: left;
}

.boxStyle {
  border: 1px solid #989898;
  border-radius: 4px;
  height: 62px;
  width: 62px;
  display: grid;
  place-items: center;
}
.recognitionEmpty-img {
  margin-top: 10rem;
  margin-right: 20rem;
  display: flex;
  justify-content: center;
}
.listing-bg {
  margin-top: 1rem;
  background-color: #f6f7fb;
  padding: 1rem 1rem;
}
.divider {
  border-top-color: rgba(0, 0, 0, 0.02);
}
.total-claimed {
  margin-top: 2rem;
}
.add-recognition {
  margin-left: 40rem;
}
.cancel-btn {
  height: 2.5rem;
  background-color: white;
  color: #000;
}
.sortable-handler {
  touch-action: none;
}
.conversion-text {
  padding-top: 1rem;
}
.lostUsd-text {
  font-weight: 600;
  background-color: #cf0a0a;
  padding: 0.75rem 0.75rem;
  color: #fff;
}
.xlsx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.docx-placeholder-image {
  height: 4rem;
  width: 66px;
}

.direct-expense-input-revenue {
  width: 78%;
  height: 53px;
  border: rgba(0, 0, 0, 0.85) !important ;
  border-radius: 4px !important;
}