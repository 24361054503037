.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem !important;
  }
}

.scrollabarChange {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7788fb;
    outline: 0;
  }
}

.projectsTable {
  .ant-table-cell {
    padding: 10px;
  }

  td {
    font-weight: normal;
    .nameTable {
      font-weight: normal;
    }
  }
  .total {
    align-items: center;
    font-size: medium;
    font-style: Bold;
  }
  .numTotal {
    text-align: right !important;
    font-size: medium;
    font-style: Bold;
  }
}

.table-card {
  .ant-table-cell {
    a {
      color: rgba(0, 89, 255, 0.61);
    }
  }
}


.searchForm {
  margin-bottom: 20px;

  .ant-input-group-wrapper {
    position: relative;

    .ant-input-affix-wrapper {
      border: 1px solid #adbdcc;
      height: 40px;
      position: relative;

      .ant-input {
        height: 35px;
        margin: -1px 0 0 0;
        padding: 0 0 0 25px;
      }

      &:not(:last-child) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .ant-input-group-addon {
      position: absolute;
      left: 4px !important;
      display: block;
      top: 5px;
      z-index: 9;

      .ant-input-search-button {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.projectsTable {
  table {
    tr {
      td {
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .addProjectBtn {
    .ant-btn-primary.primary-btn {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}

.totalLabel {
  margin-left: 10px;
  margin-top: 10px;
  display: block;
}

.textAlign {
  text-align: right;
}

.departmentDropdown {
  // margin-left: 640px;
  // display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.css-1v99tuv {
  width: 200px;
}

.ant-table-thead .thisMonth {
  font-size: 14px;
}

.ant-table-tbody .thisMonth {
  width: 92px;
  height: 13px;
  color: #485359;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-align: right !important;
  text-transform: uppercase;
}

.ant-table-thead .nextMonth {
  font-size: 14px;
}

.ant-table-tbody .nextMonth {
  width: 92px;
  height: 13px;
  color: #485359;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-align: right !important;
  text-transform: uppercase;
}

.ant-table-thead .futureMonth {
  font-size: 14px;
}

.ant-table-tbody .futureMonth {
  width: 92px;
  height: 13px;
  color: #485359;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  text-align: right !important;
  text-transform: uppercase;
}

.ant-table-thead .futureMonthTitle {
  background: #c3f0fa;
  font-style: normal;
  color: #485359;
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
}

.ant-table-thead .thisMonthTitle {
  color: #485359;
  background: #caf9ea;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 13px;
  text-transform: uppercase;
}

.ant-table-thead .nextMonthTitle {
  color: #485359;
  background: #cbdcfd;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  text-transform: uppercase;
}

#ThisMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-transform: none;
}

#NextMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(252, 252, 252);
  text-transform: none;
}

#FutureMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-transform: none;
}
#ThisMonthhover p {
  display: inline-block;
}
#NextMonthhover p {
  display: inline-block;
}
#FutureMonthhover p {
  display: inline-block;
}
.showVarienceDetailsThisMonth:hover ~ #ThisMonthhover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

.showVarienceDetailsNextMonth:hover ~ #NextMonthhover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

.showVarienceDetailsFutureMonth:hover ~ #FutureMonthhover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
