.curve-one {
  position: absolute;
  width: 511px;
  height: 861.66px;
  left: -407.76px;
  top: -175.11px;
  background: linear-gradient(357.97deg, #7788fb 2.67%, #5054bc 99.57%);
  opacity: 0.7;
  border-radius: 500px;
  transform: rotate(-29.97deg);
}

@media only screen and (max-width: 767px) {
  .curve-one {
    display: none;
  }
}
