.powered-by {
  position: relative;
  margin-bottom: 100px;
  text-align: center;
  img {
    padding-bottom: 7px;
  }
}

.powered-by__text {
  margin-right: 10px;
  font-size: 13px;
  color: #9198a5;
}

@media only screen and (max-width: 600px) {
  .powered-by {
    margin-bottom: 20px;
  }
}
