.monthlySlotsDrawer {
  .slots-title {
    padding: 0 15px;
    margin: 0 0 15px;
  }
  .ant-drawer-header {
    border-bottom: 1px solid #5c67e5;
  }
  .view {
    margin-top: -15px;
    margin-left: 15px;
    color: grey;
  }
  .viewSlotsCol {
    padding: 0 15px;
    margin-bottom: 10px;
    input {
      &.ant-input {
        height: auto;
        border: 1px solid #adbdcc;
        background: #fff;
        color: #7c94ab;
        line-height: 1.8;
        opacity: 1;
        margin: 0;
        &:hover {
          border: 1px solid #adbdcc;
          background:#fff;
        }
      }
    }
    .display_error {
      margin-top: 10px;
    }
    .closeSlotBtn {
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid rgba(0, 0, 0, 0.85);
      border-radius: 4px;
      height: 33px;
      line-height: 33px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .ant-btn-primary {
      &.submitBtn {
        border-radius: 4px;
        color: #fff;
        border: none;
        padding: 0px 20px;
        font-size: 15px;
        height: 33px;
        background: #5c67e5;
        line-height: 33px;
        margin-right: 20px;
      }
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
}
