.popup-modal {
    position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
}

.popup {
    background-color: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        text-align: center;
        width: 400px;
        max-width: 80%;
        z-index: 9999;
}

.popup-content .title {
    margin-bottom: 20px;
}

.popup-content .note {
    font-size: 14px;
        margin-top: 10px;
        color: #888;
        margin-bottom: 40px;
}

.popup-content .buttons {
    display: flex;
        justify-content: center;
}

.popup-content button {
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.popup-content button.yes {
    background-color: #5C67E5;
    color: white;
    width: 40%;
}

.popup-content button.no {
    background-color: #FFFFFF;
    color: black;
    border: 1px solid black;
    width: 40%;
}
