@import "./variables";
@import "fonts";
// body > #root > div {
//   height: 100vh;
// }

body {
  font-family: "Montserrat-Medium", Arial, sans-serif!important;
  font-style: normal;
  margin: 0;
  position: relative;
  background: #fff;
}
.text-right {
  text-align: right;
}
.mt20 {
  margin-top: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb15 {
  margin-bottom: 15px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7788fb !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #4b5fea !important;
}

.actionBtns {
  text-align: center;
}
.ant-table-thead {
  .ant-table-cell {
    color: #8595a5;
  }
}
.ant-table-tbody {
  .ant-table-cell {
    color: #1e2225;
    font-family: "Montserrat-Medium", Arial, sans-serif;
    font-weight: normal;
    a {
      color: #1e2225;
      font-family: "Montserrat-Bold", Arial, sans-serif;
      font-weight: normal;
    }
  }
}
.white-bg-widget {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(72, 83, 89, 0.12);
  border-radius: 4px;
  padding: 35px 30px;
}
.side-menu-container .ant-layout-sider {
  position: fixed;
  left: 0;
  z-index: 99;
  height: 100%;
  font-family: "Montserrat-Bold", Arial, sans-serif;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  margin-left: 200px;
}
.ant-layout-header.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  box-shadow: 0px 4px 20px rgb(72 83 89 / 12%);
  font-family: "Montserrat-Bold", Arial, sans-serif;
}
.toggle1 {
  .ant-layout {
    margin-left: 80px !important;
  }
}
