.ant-drawer.ant-drawer-right.add_project {
  .css-2b097c-container {
    margin-top: 10px;
  }
  .css-yk16xz-control {
    height: 40px;
    border-radius: 2px;
  }
  .input--textarea {
    // background-color: #fff;
    // border: 1px solid #d9d9d9;
    height: 120px;
    // font-size: 14px;
    // width: 100%;
  }
}

.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}
.MSA {
  width: 36;
  height: 20;
  margin-left: 85%;
  margin-top: -11%;
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.expanded-details-expense {
  width: 95%;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #e8e8e8; 
  background-color: #f9f9f9; 
}

.expanded-details span {
  display: block;
  margin-bottom: 4px;
}

.details-row{
  display: flex;
  justify-content: space-between;
}
.light-font-poexpansion{
  font-weight: lighter;
  color: #8595A5;
}
.select-dropdown-month {
  width: 350px;
 }
