.ant-drawer.ant-drawer-right.add_talent {
  .css-2b097c-container {
    margin-top: 10px;
  }
  .css-yk16xz-control {
    height: 40px;
    width: 290px;
    border-radius: 2px;
    padding-right: 5px;
  }
  .select--field {
    border-radius: 2px;
    padding-right: 200px;
  }
}

.button-container {
  margin-top: 20px;
  padding-right: 200px;
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_talent {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.select--field {
  width: 90%;
}
