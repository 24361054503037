.ProjectStuSummary-Info {
  .projectStuSummaryHeader {
    margin-bottom: 20px;
    .imgColumn {
      text-align: right;
    }
  }

 
}
