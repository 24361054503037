.edit_project_form {
  .ant-input.form-group__input.input--field--edit {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
  .css-2b097c-container {
    height: auto;
  }
  .ant-input-group {
    margin-bottom: 20px;
    .ant-select {
      width: 100%;
      .ant-select-selector {
        padding-top: 5px;
        height: auto;
      }
    }
  }
}
.Spacing{
  margin-top: 1.3rem !important;
}

.info-btn {
  margin-left: 10px;
}

.edit-disabled-button {
  background-color: #f5f5f5 !important;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  height: 40px;
  color:grey !important;
  background:#f5f5f5 !important;
  &:hover {
    background: #f5f5f5 !important;
    color:grey !important;
  }
}