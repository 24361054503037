.actionBtnAttachment {
  cursor: pointer;
}

.AttachmentsTable {
  margin-top: 20px;
}
.xlsx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.attachment-image {
  border: 1px solid #989898;
  border-radius: 4px;
}
.docx-placeholder-image {
  height: 4rem;
  width: 66px;
}
