.rate-radio-btn{
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 4;
    border: none;
    width: 100%;
    height: 50%;
    color: black;
    font-size: 16px;
}

.rate-radio-btn2{
    margin-bottom: 5%;
    border-radius: 4;
    border: none;
    width: 100%;
    height: 50%;
    color: black;
    font-size: 16px;
}

.forexRateModal{
    height: 100%;
    font-size: x-large;
    margin-top: 10%;
    position: fixed;
    margin-left: 35%;
}

.rate-save-btn{
 margin-top: 20px; 
 margin-right: 10px;  
 width: 40%;
 background-color: #5c67e5;
 color: #fff;
}

.rate-cancel-btn{
    margin-top: 20px; 
    margin-right: 10px;  
    width: 40%;
    color: black;
   }