.primary-btn-poServices {
  margin-right: 10px;
  border: none;
  background-color: #5c67e5;
  color: white;
  height: 40px;
  width: 150px;
  font-size: 16px;
  cursor: pointer;
}
.services_primary-btn {
  margin-right: 10px;
  border: 1px solid black;
  background-color: #ffffff;
  color: black;
  height: 40px;
  width: 150px;
  font-size: 16px;

}

.Po_services_total {
  margin-left: 75%;
  font-size: 15px;
}

.edit_PoServices {
  margin-right: 10px;
  cursor: pointer;
}

.btn-con {
  margin-right: 0px !important;
}

.PoServices_input {
  background-color: white;
  text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
