.textAlignCurrency {
  text-align: right;
}

.sold-amount {
  h1 {
    font-weight: 350;
    font-size: 32px;
    color: #5c67e5;
    small {
      font-size: 16px;
      color: #1e2225;
      margin: 0 0 0 7px;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}
.textGreen {
  color: green;
}

.textRed {
  color: red;
}

.textAlignRight {
  text-align: right;
}

.textHeadingRevenue {
  font-size: 16px;
  font-weight: lighter;
}
.textValueRevenue {
  font-size: 16px;
}
.textHeadingEfficiency {
  font-size: 18px;
  font-weight: lighter;
}
.textValueEfficiency {
  font-size: 26px;
}

.cardHeight {
  min-height: 170px;
}

.pr20 {
  padding-right: 20px;
}

.cardMargin10 {
  margin-right: 10px;
}

.ant-card-head-title {
  font-size: 16px;
  padding-block-end: 0;
}

@media all and (max-width: 1400px) {
  .textHeadingRevenue {
    font-size: 13px;
    font-weight: lighter;
  }
  .textValueRevenue {
    font-size: 13px;
  }

  .overflowEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 70%;
    vertical-align: top;
  }
  .overflowEllipsisValue {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cardHeight {
    height: 150px;
  }
}

@media all and (max-width: 1200px) {
  .textHeadingRevenue {
    font-size: 11px;
    font-weight: lighter;
  }
  .textValueRevenue {
    font-size: 11px;
  }
  .textHeadingEfficiency {
    font-size: 14px;
    font-weight: lighter;
  }
  .textValueEfficiency {
    font-size: 18px;
  }
  .overflowEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 70%;
    vertical-align: top;
  }
  .overflowEllipsisValue {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cardHeight {
    height: 140px;
  }
}

@media all and (max-width: 767px) {
  .textHeadingRevenue {
    font-size: 14px;
    font-weight: lighter;
  }
  .textValueRevenue {
    font-size: 14px;
  }
  .textHeadingEfficiency {
    font-size: 18px;
    font-weight: lighter;
  }
  .textValueEfficiency {
    font-size: 26px;
  }

  .cardHeight {
    min-height: 170px;
  }
  .overflowEllipsis {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 70%;
    vertical-align: top;
  }

  .overflowEllipsisValue {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.pr4 {
  padding-right: 0.4rem;
}
#buInfo {
  display: none;
}
.showBuDetail:hover ~ #buInfo {
  border: #666 2px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: #e3e3e3;
  left: 0px;
  margin: 15px;
  width: 300px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
