.retainer-button-container {
  margin-bottom: 25px;
}



.primary-btn.attchments {
  height: 25px;
  width: 50px;
  padding: 0;
  background: #fff;
  border: 1px solid #1e2225;
  color: #1e2225;
  font-size: 13px;
  margin-right: 40rem;
  float: right;
}

.ant-btn-primary.primary-btn.attchments:hover {
  background: #fff;
}

.AttachmentsModal {
  .ant-modal-footer {
    .ant-btn.ant-btn-primary {
      display: none;
    }
  }
}

.button-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
}
.cancel-btn {
  height: 38px;
  width: 75px;
  background: #fff;
  border: 1px solid #1e2225;
  color: #1e2225;
  font-size: 13px;
  border-radius: 8%;
}

.textAlign {
  text-align: right;
}

.boxStyle {
  border: 1px solid #989898;
  border-radius: 4px;
  height: 62px;
  width: 62px;
  display: grid;
  place-items: center;
}
.recognitionEmpty-img {
  margin-top: 10rem;
  margin-right: 20rem;
  display: flex;
  justify-content: center;
}
.listing-bg {
  margin-top: 1rem;
  background-color: #f6f7fb;
  padding: 1rem 1rem;
}

.divider {
  border-top-color: rgba(0, 0, 0, 0.02);
}
.EarnedVsSold {
  margin-top: 2rem;
}

.poEditButton {
  cursor: pointer;
}
.input--field {
  width: 90% !important;
}
.add-recognition {
  margin-left: 40rem;
}
.conversion-text {
  padding-top: 1rem;
}

.main-div {
  margin-top: 1rem;
  background-color: #fff;
  padding: 1rem;
  border: 1px dashed #73c8ee;
}
.primary-btn.addReuseButton {
  margin-top: 1.4rem;
}

ul[title="Select Month"] {
  height: 250px !important;
  overflow: auto;
}
.attachment-image {
  border: 1px solid #989898;
  border-radius: 4px;
}
.direct-expense-input {
  width: 90%;
  height: 53px;
  border: rgba(0, 0, 0, 0.85) !important ;
  border-radius: 4px !important;
}