.side-menu-container {
  background: #485359;
  .side-menu {
    background: #485359;
    border-right: 1px solid #485359;
    .poweredBy {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%);
      p {
        color: #fff;
        text-align: center;
        line-height: normal;
      }
    }
    .ant-menu-item,
    .ant-menu-submenu {
      color: #fff;
      font-weight: 350;
      font-size: 16px;
      background: #485359;
      z-index: 1;
      .ant-menu-item-icon {
        font-size: 22px;
      }
    }
    .ant-menu-item {
      padding: 30px;
      &:hover {
        background: #7788fb;
        color: #fff;
        border-radius: 4px;
      }
    }
    .ant-menu-submenu {
      padding: 0;
      .ant-menu-submenu-title {
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        margin-bottom: 0;
        &:hover {
          background: #7788fb;
          color: #fff;
          border-radius: 4px;
          & > .ant-menu-submenu-arrow {
            color: #fff;
          }
        }
      }
      .ant-menu-submenu-arrow {
        color: #fff !important;
        &:before,
        &:after {
          width: 8px;
          height: 2.5px;
        }
      }
      .ant-menu-sub {
        background: #485359;
        .ant-menu-item {
          .ant-menu-title-content {
            padding-left: 5px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .ant-layout-sider-trigger {
    background: #485359;
  }
}

.poweredBy {
  background-color: #485359;
  bottom: 0%;
  position: fixed;
  z-index: 100;
  color: #fff;
  padding: 0px 25px 10px 25px;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  max-width: 220px;
  margin-top: 5px;
}

.poweredByText {
  color: #fff;
  align-self: center;
  text-align: center;
  width: max-content;
  z-index: 100;
  margin-left: 10%;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  font-size: 22px !important;
}
.ant-layout-sider-trigger {
  display: none;
}

.ant-layout-sider-children {
  position: relative;
}
.ant-menu-inline-collapsed {
  // .ant-menu-item{
  //   &.ant-menu-item-selected{
  //     line-height: 48px;
  //   }
  // }
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0 !important;
  height: auto;
  line-height: 65px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  line-height: normal !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -42%);
  top: 50%;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin: 0;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0 !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}
