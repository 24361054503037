// @import '../../variables';
.side-menu-container {
  .ant-layout-sider {
    background: #485359;
    flex: 0 0 220px !important;
    min-width: 220px !important;
    width: 220px !important;
    max-width: 220px !important;
    transition: all 0.2s;
    padding-bottom: 0px;
    &.ant-layout-sider-collapsed {
      flex: 0 0 80px !important;
      min-width: 80px !important;
      width: 80px !important;
      max-width: 80px !important;
      transition: all 0.2s;
    }
    .ant-layout-sider-children {
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px #e6e6e6;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #7680e8;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #5c67e5;
      }
    }
  }
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  margin-left: 220px;
  transition: all 0.2s;
}
.ant-menu-light.ant-menu-submenu {
  .ant-menu-item:hover {
    color: #fff;
    background: #7788fb;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected {
    color: #fff;
  }
}
.ant-layout-header {
  &.header {
    background: #fff;
    padding: 0;
    .trigger {
      padding: 3px 8px 5px 23px;
      font-size: 22px;
      cursor: pointer;
      transition: color 0.3s;
    }
    h1 {
      padding: 0;
      cursor: pointer;
      transition: color 0.3s;
      font-weight: 350;
      font-size: 26px;
      margin: 0 0 0 10px;
      line-height: 66px;
    }
    .anticon-menu-fold {
      margin: -9px 0 0 0;
    }
    h6 {
      font-size: 15px;
      color: #1e2225;
      text-align: right;
      margin: 23px 20px 9px 6px;
    }
  }
}
.main-content-container {
  margin: 64px 16px 24px 16px;
  padding: 24px;
}

// h1{
//   color: $primary-color;
// }
