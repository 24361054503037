.ant-card.basic-info {
  margin-bottom: 20px;
}

.ant-input-group.project-select {
  padding-left: 0;
  margin: 20px 0 20px 0;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      padding-top: 10px;
      height: 50px;
    }
  }
}
.searchableDropdown {
  &.ant-select-focused {
    .ant-select-selection-placeholder {
      color: #ddd;
    }
  }
  .ant-select-selection-placeholder {
    color: #333;
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    line-height: 48px;
    height: auto;
  }
}
.ant-row.table-row {
  padding: 20px 10px 0px 10px;
}

.back-button {
  margin: 0;
  color: #5c67e5;
  cursor: pointer;
  img {
    padding-right: 8px;
    padding-bottom: 5px;
  }
}

.PurchaseOrderTable {
  margin-top: 20px;
}
.customCard {
  margin: 0px 0 0 0 !important;
}

.earnedExpandedOut {
  margin: -50px 0 0 0;
  @media (max-width: 767px) {
    margin: 0 0 0 0;
  }
}

.futureSales {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.archive_status {
  align-self: center;
  color: red;
}
#button1 {
  float: left;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 7px 24px;
  font-size: 16px;
  height: 40px;

  background: #5c67e5;
  margin-bottom: 20px;
}

.ant-table-tbody .earned_Data{
  text-align: right;
}

.ant-table-tbody .exec_Data{
  text-align: right;
}

.ant-table-tbody .alloc_Data{
  text-align: right;
}

.ant-table-tbody .expended_Data{
  text-align: right;
}

.ant-table-thead .exec_Data{
  text-align: center;
}

.ant-table-thead .alloc_Data{
  text-align: center;
}

.ant-table-thead .expended_Data{
  text-align: center;
}

