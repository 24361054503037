.pdf-placeholder-image {
  height: 4rem;
  width: 66px;
}
.attachment {
  margin-bottom: 1rem;
}
.xlsx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.render-Image {
  border: 1px solid #989898;
  border-radius: 4px;
}
.docx-placeholder-image {
  height: 4rem;
  width: 66px;
}