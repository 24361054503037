.curve-two {
  position: absolute;
  width: 306.22px;
  height: 516.35px;
  left: 1694.87px;
  top: 500px;
  /* Secondary/Graph1 */
  background: #2cdcb8;
  opacity: 0.7;
  border-radius: 299.625px;
  transform: rotate(143.81deg);
}

@media only screen and (max-width: 767px) {
  .curve-two {
    display: none;
  }
}
