.circle-one {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 92px;
  top: 515px;
  /* Secondary/graph4 */
  background: #7788fb;
  opacity: 0.6;
}

@media only screen and (max-width: 767px) {
  .circle-one {
    display: none;
  }
}
