.managersFilter {
  .css-1r4vtzz {
    width: 100%;
  }
}
.departmentsFilter {
  .css-1r4vtzz {
    width: 100%;
  }
}
.RevenueGraph {
  height: 100%;
}
.RevenueGraphCard {
  padding-right: 20px !important;
}
.EffortGraph {
  height: 100%;
}
.EffortGraphCard {
  padding-left: 0px !important;
}

.select--field {
  width: 90%;
}

.textValue {
  font-size: medium;
}

.monthOptions {
  margin-top: 0px !important;
  width: 100% !important;
  padding-left: 80px;
}

.Amount {
  text-align: right;
}

.ant-popover-inner-content {
  background-color: black !important;
  color: white !important;
}

.loader_efficiency {
  left: 36% !important;
  background-color: lightgray !important;
}

.column-style {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.column2-style {
  padding-left: 20px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}