.circle-two {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 1367.9px;
  top: 664.89px;
  /* Secondary/Graph1 */
  background: #2cdcb8;
  opacity: 0.6;
  transform: rotate(173.78deg);
}

@media only screen and (max-width: 767px) {
  .circle-two {
    display: none;
  }
}
