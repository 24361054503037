.table-card.revenue-table {
  .table-bold-rows {
    .ant-table-cell {
      font-weight: bold;
    }
  }
  margin-bottom: 10px;
}

.talentTable {
  width: 100%;
}

.PresentStatus {
  background-color: #b2bbf7;
}

.FutureStatus {
  background-color: #f8e0b1;
}

.utilized {
  align-items: center;
}

.recognition {
  align-items: center;
}
.tableContent {
  text-align: right !important;
  align-items: right;
}
.Total {
  text-align: right;
  font-size: larger;
}

.edit_Loader_revenue {
  width: max-content !important;
  left:38% !important;
  top:25% !important;
  background-color: lightgray !important;
}
