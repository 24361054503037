
.AddPoButton {
  text-align: right;
  .add-po {
    float: right;
  }
}



.actionBtns {
  .view_project {
    margin-right: 10px;
  }
  span {
    width: max-content;
  }
}

.warnBtns {
  .view_project {
    margin-right: 5px;
  }
  span {
    width: max-content;
  }
}
.table-card {
  width: 100%;
  margin-top: 20px;
  overflow-x: scroll;
  margin-right: 10%;
  .ant-table-cell {
    a {
      color: blue;
    }
  }
}
@media (max-width: 767px) {
  .purchaseOrderTitle {
    text-align: center;
  }
}

.invoice-list-div {
  background-color: #f6f7fb;
  margin-top: 1rem;
  padding-top: 0.2rem;
}

.no-invoice-div {
  display: grid;
  place-items: center;
  margin-top: 5rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.icon-class {
  margin-inline-end: 0.5rem;
  color: #5c67e5;
  cursor: pointer;
}

.filled-icon-class {
  margin-inline-end: 0.5rem;
  color: #5c67e5;
}

.delete-icon-class {
  margin-inline-end: 0.5rem;
  color: red;
  cursor: pointer;
}

.divider-margin {
  margin: 0.5rem 0;
}

.pb-20 {
  padding-bottom: 20px;
}

.financialMonth {
  margin-bottom: 0.5rem;
}
.RevenueProgress {
  margin-bottom: 10px;
  margin-bottom: 5px;

}
.sentInvoice {
  color: #5c67e5;
}
.amount{
  position: absolute;
  left:50%;
}
.planned{
  position: absolute;
  left:55%;
}

.spin-Details {
  position: absolute;
  top: 60%;
  left: 50%;
}
.card-Details {
  position: absolute;
  top: 40%;
  left: 40%;
  background: white;
}

.NoPurchanseOrder{
  text-align: center;
  font-size: 16px;
  color: #999;
  margin-top: 20px;
  margin-bottom: 20px;
}
