.stuSold_table,
.valueSold_table {
  margin-bottom: 0;
}

.valueSold_table {
  font-weight: normal;
}
.totalLabel {
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}
.totalNumber {
  text-align: right;
}
