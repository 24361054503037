.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem !important;
  }
}

.addProjectBtn {
  //float: right;
  display: inline-block;
  margin-left: 15px;
}
.scrollabarChange {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7788fb;
    outline: 0;
  }
}
.projectsTable {
  .ant-table-cell {
    padding: 15px;
  }
  td {
    font-weight: normal;

    .nameTable {
      font-weight: normal;
    }
  }
  .Revenue {
    background-color: #caf9ea;
    color: black;
  }

  .Invoice {
    background-color: #cbdcfd;
    color: black;
  }
  .projSummary1 {
    text-align: right;
    background-color: #caf9ea;
    color: black;
    font-size: medium;
    font-style: Bold;
  }
  .projSummary2 {
    text-align: right;
    background-color: #cbdcfd;
    color: black;
    font-size: medium;
    font-style: Bold;
  }
  .totalProject {
    align-items: center;
    font-size: medium;
    font-style: Bold;
    color: black;
  }
}

.actionBtns {
  .view_project {
    margin-right: 10px;
  }
}

.table-card {
  .ant-table-cell {
    a {
      color: blue;
    }
  }
}

.searchForm {
  margin-bottom: 20px;
  .ant-input-group-wrapper {
    position: relative;
    .ant-input-affix-wrapper {
      border: 1px solid #adbdcc;
      height: 40px;
      position: relative;
      .ant-input {
        height: 35px;
        margin: -1px 0 0 0;
        padding: 0 0 0 25px;
      }
      &:not(:last-child) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .ant-input-group-addon {
      position: absolute;
      left: 4px !important;
      display: block;
      top: 5px;
      z-index: 9;
      .ant-input-search-button {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.projectsTable {
  table {
    tr {
      td {
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .addProjectBtn {
    .ant-btn-primary.primary-btn {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}

.totalLabel {
  margin-left: 10px;
  margin-left: 15px;
  margin-top: 5px;
  display: block;
}

.geography{
  margin-left: 15px;
  margin-top: 5px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.textAlign {
  text-align: right;
}
.Revenue4 {
  color: orange;
}

.Revenue5 {
  color: red;
}
.Invoicing4 {
  color: red;
}
.Invoicing5 {
  color: orange;
}
#Unaccountedhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
#UnaccountedInvoicinghover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.showVarienceOfUnaccounted:hover ~ #Unaccountedhover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
.showVarienceOfUnaccountedInvoicing:hover ~ #UnaccountedInvoicinghover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

.modal-container {
  margin-left: 18%;
}

.locationDropdown {
  margin-left: 1%;
  padding-left: 5px !important;
}

.column{
  padding-left: 35px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.custom-container {
  border-radius: 4px;
  position: absolute;
  z-index: 5;
  width: 150px;
}
