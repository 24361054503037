.ant-btn-primary.primary-btn {
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 7px 28px;
  font-size: 16px;
  height: 40px;
  background: #5c67e5;
  &:hover {
    background: #7788fb;
  }
}
