.profileOut {
  // .profilePic {
  //     width: 100px;
  //     height: 100px;
  //     overflow: hidden;
  //     margin: 0 auto;
  //     border-radius: 50%;
  //   }
  h4 {
    text-align: center;
    font-weight: 350;
    font-size: 25px;
    margin: 15px 0 5px 0;
    padding: 0;
  }
  h5 {
    text-align: center;
    font-size: 15px;
    padding-right: 0px;
  }
  p {
    text-align: center;
  }
}
.capcityReport {
  h5 {
    font-weight: 350;
    font-size: 22px;
  }
}
.backBtn {
  img {
    float: left;
  }
  span {
    float: left;
    color: #5c67e5;
    margin: 0 0 0 8px;
  }
}
.profileDetails {
  label,
  p {
    margin: 0 0 20px 0;
    display: block;
  }
}

.ProjectByHoursCard {
  height: 100%;
}
.ActivityHoursCard {
  height: 100%;
}
