@font-face {
  font-family: "Montserrat-Regular";
  src: 
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: 
  url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: 
  url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


