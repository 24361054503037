.title_snapshot .ant-table-thead th {
  text-align: left !important;
}

.snapShot_dateDropdown {
  width: 100%;
}

.snapshotTable .ant-table-thead > tr > th {
  text-align: left !important;
  color: #485359;
  font-weight: 600;
  font-size: 15px;
  line-height: 13px;
  text-transform: uppercase ;
}

