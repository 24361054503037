.modal-body {
  padding: 90px;
}

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  width: 450px;
  background-color: #fff;
  height: 250px;
  border: 1px solid black;
  border-radius: 4px;
}

.modal-body__button {
  background-color: #e62254;
  color: white;
  padding: 0.5rem;
  margin: 0.4rem;
  cursor: pointer;
  font-size: 20px;
  height: min-content;
  border: none;
  width: 120px;
}

.modal-body__button.cancel--button {
  background-color: white;
  color: #000;
  border: 1px solid #000;
}

.modal-text {
  margin-left: 10px;
  font-weight: 350;
  font-size: 15px;
}
