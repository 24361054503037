.ant-drawer.ant-drawer-right.add_project {
  .css-2b097c-container {
    margin-top: 10px;
  }
  .css-yk16xz-control {
    height: 40px;
    border-radius: 2px;
  }
}

.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

.MSA {
  width: 36;
  height: 20;
  margin-left: 85%;
  margin-top: -11%;
}
@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
