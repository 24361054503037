.password-popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-popup {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 400px;
    max-width: 80%;
}

.password-popup .note {
    font-size: 14px;
    margin-top: 10px;
    color: #888;
}


.password-popup input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.password-popup .title {
    margin-bottom: 30px;
}

.password-popup .buttons {
    display: flex;
    justify-content: center;
}

.password-popup button {
    margin: 0 10px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.password-popup button.confirm {
    background-color: #5C67E5;
    color: white;
}

.password-popup button.cancel {
    background-color: #FFFFFF;
    color: black;
    border: 1px solid black;
}

