.talentTable {
  .ant-table-thead .ant-table-cell {
    color: #485359;
    font-weight: bold;
    font-size: 14px;
    line-height: 13px;
    text-transform: uppercase;
  }


  td {
    font-weight: normal;

    .nameTable {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .buTable {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .bandTable {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .skillTable {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .tma {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .nma {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
    .fma {
      font-weight: normal;
      color: #1e2225;
      font-size: 16px;
    }
  }
}

// Hover functionality
#ThisMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
  z-index: 99999 !important;
}
#statusHover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
#NextMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
#FutureMonthhover {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.showPerDetails:hover ~ #ThisMonthhover {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100 !important;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

.showPerDetails:hover ~ #statusHover {
  border: black;
  padding: 5px 10px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 250px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
.showPerDetails:hover ~ #NextMonthhover {
  border: black;
  padding: 5px 10px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 250px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
.showPerDetails:hover ~ #FutureMonthhover {
  border: black;
  padding: 5px 10px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 250px;

  position: absolute;
  top: 15px;
  text-decoration: none;
}

.Active:hover #statusHover {
  border: black;
  padding: 5px 10px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 250px;

  position: absolute;
  top: 15px;
  text-decoration: none;
}

#ThisMonthhover p {
  display: inline-block;
}
#NextMonthhover p {
  display: inline-block;
}
#FutureMonthhover p {
  display: inline-block;
}

.activeStatus {
  font-weight: normal;
  color: #00bf79;
}

.inActivestatus {
  font-weight: normal;
  color: #e62254;
}

.totalLabel {
  margin-left: 30px;
  margin-top: 5px;
  display: block;
}
