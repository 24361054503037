.poListingTable {
  
  .poNumberLink {
    font-weight: bold;
  }
  .Purchase {
    padding-bottom: 0%;
  }
  .Name {
    text-size-adjust: "5px";
  }
  tbody > tr > :nth-child(5),
  tbody > tr > :nth-child(6),
  tbody > tr > :nth-child(7) {
    text-align: right;
  }
  .expandedTable {
    tbody > tr > :nth-child(1),
    tbody > tr > :nth-child(2),
    tbody > tr > :nth-child(3),
    tbody > tr > :nth-child(4),
    tbody > tr > :nth-child(5),
    tbody > tr > :nth-child(6),
    tbody > tr > :nth-child(7),
    tbody > tr > :nth-child(8) {
      text-align: right;
    }
  }
  .planned {
    text-align: center;
  }
  .unaccounted {
    text-align: right;
  }
  .totalPO {
    align-items: center;
    font-size: medium;
    font-style: Bold;
    color: black;
  }
  .poSummary1 {
    text-align: right;
    color: black;
    font-size: medium;
    font-style: Bold;
  }
}

.warning-img {
  width: 20px;
  height: 20px;
}

.warning-text {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  z-index: 1;
  width: max-content;
}

.warning-img:hover + .warning-text {
  display: block;
}
