.login-wrapper:before {
  content: "";
  position: fixed;
  width: 381px;
  height: 535px;
  left: 0;
  top: 0;
  background: url(../../assets/images/violet.svg) no-repeat;
  background-size: 59%;
}

.login-wrapper:after {
  content: "";
  position: fixed;
  width: 241px;
  height: 250px;
  right: 0;
  bottom: 0;
  background: url(../../assets/images/green.svg) no-repeat;
}

.login-container {
  box-shadow: 0px 4px 20px rgba(72, 83, 89, 0.12);
  width: 540px;
  padding: 40px 90px 140px 90px;
  margin: 0 auto;
  background: #fff;
  border-top: 6px solid #5c67e5;
}

.login-container .logo {
  margin: 0;
  padding: 0;
  text-align: center;
}

.login-container h5 {
  font-weight: 350;
  font-size: 22px;
  line-height: 26px;
  color: #1e2225;
  margin: 50px 0 30px 0;
  padding-right: 20rem;
}

.logo {
  margin: 0;
  padding: 0;
}

h5 {
  font-weight: 350;
  font-size: 22px;
  line-height: 26px;
  color: #1e2225;
  margin: 10px 0 30px 0;
  padding-right: 20rem;
}

.ant-input {
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  margin-bottom: 20px;
  height: 50px;
}

.ant-input-password {
  background: #f6f7fb;
  border: none;
  height: 50px;
  margin-bottom: 10px;
}

.form-group__input.email--input {
  background: #f6f7fb;
  border: none;
}

.form-group__input.password--input {
  background: #f6f7fb;
  border: none;
  .ant-input {
    height: 40px;
    background: #f6f7fb;
  }
  .ant-input-suffix {
    font-size: 20px;
  }
}

.display_error {
  color: red;
  font-size: 13px;
  font-weight: normal;
}

.ant-checkbox-wrapper.login-remember-me {
  font-size: 13px;
  color: #9198a5;
}

@media only screen and (max-width: 600px) {
  .login-container {
    width: 100%;
    padding: 30px 30px;
  }
}
