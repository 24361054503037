
.add_creditNote .ant-drawer-header {
    border-bottom: 2px solid #5C67E5; 
  }
  
 
  .add_creditNote .ant-drawer-body {
    padding: 16px;
  }
  
  .reviewTab .ant-select {
    width: 260px;
  }
  
  .reviewTab .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  
  .reviewTab .ant-select-selection-item span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
  }
  
  .ant-input {
    background: #fff;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #adbdcc;
    height: 40px;
  }
 .review-type-input-container {
  margin-top: 10px; 
  margin-right: 40px;
}

.review-type-input {
  height: 40px; 
}

.review-type-label{
  margin-bottom: 10px;
}
