.classification_years .ant-select {
  width: 50%;
  margin-left: 5%;
  background: #ffffff;
  border-radius: 17px;
}

.Classification_Edit {
  background-color: #5c67e5;
  width: 100px;
  height: 40px;
  margin-top: 14%;
  border-radius: 4px;
  text-align: center;
  margin-left: 35%;
}

.cancel{
  border: 1px solid black;
}