.table1 {
  margin-bottom: 20px;
}

.ant-input-group.project-select {
  padding-left: 0;
  margin: 20px 0 20px 0;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      padding-top: 10px;
      height: 50px;
    }
  }
}
.searchableDropdown {
  &.ant-select-focused {
    .ant-select-selection-placeholder {
      color: #ddd;
    }
  }
  .ant-select-selection-placeholder {
    color: #333;
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    line-height: 48px;
    height: auto;
  }
}
.ant-row.table-row {
  padding: 20px 10px 0px 10px;
}

.back-button {
  margin: 0;
  color: #5c67e5;
  margin-bottom: 20px;
  cursor: pointer;

  img {
    padding-right: 8px;
    padding-bottom: 5px;
  }
}
#button1 {
  float: "left";
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 7px 24px;
  font-size: 16px;
  height: 40px;
  background: #5c67e5;
  margin-bottom: 20px;
}

.PurchaseOrderTable {
  margin-top: 20px;
}
.customCard {
  margin: 0px 0 0 0 !important;
}

.earnedExpandedOut {
  margin: -50px 0 0 0;
  @media (max-width: 767px) {
    margin: 0 0 0 0;
  }
}

.futureSales {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.archive_status {
  align-self: center;
  color: red;
}

.font_color_left {
  color: #485359;
  font-size: 16px;
  font-weight: 400;
}
.projectNameCard {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.talentDetailTable {
  padding-left: 12px !important;
}
.recognisedTable {
  width: 100%;
  margin-left: 12px !important;
  margin-top: 20px !important;
}

.recognisedTable tr td {
  border: 1px solid #d0dae6;
  text-align: left;
  padding: 16px 16px 16px 24px;
  background-color: #fff;
}
.recognisedTable table {
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.tableTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #1e2225;
  width: 25%;
}
.tableDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1e2225;
}
.select--field {
  width: 24px !important;
}
.thisMonth {
  text-align: center !important;
  font-weight: normal !important;
  font-style: Bold !important;
  font-size: 13px;
  line-height: 13px;
  color: #0c0e0e !important ;
  background: #caf9ea !important;
  width: 109px;
  text-transform: uppercase;
}
.nextMonth {
  text-align: center !important;
  font-weight: normal !important;
  font-style: Bold !important;
  font-size: 13px;
  line-height: 13px;
  color: #101314 !important ;
  background: #cbdcfd !important;
  width: 109px;
  text-transform: uppercase;
}
.futureMonth {
  text-align: center !important;
  font-weight: normal !important;
  font-style: Bold !important;
  font-size: 13px;
  line-height: 13px;
  color: #0c0e10 !important ;
  background: #c3f0fa !important;
  width: 109px;
  text-transform: uppercase;
}
.allocation {
  text-align: left;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 1px;
  line-height: 13px;
  color: #485359 !important ;
}
.Summary {
  text-align: right;
  font-weight: 400;
  font-size: 14px;
}

.titleThisMonth {
  text-align: right !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 13px;
  line-height: 13px;
  color: #485359 !important ;
  background: #caf9ea !important;
  width: 109px;
}

.titleNextMonth {
  text-align: right !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 13px;
  line-height: 13px;
  color: #485359 !important ;
  background: #cbdcfd !important;
  width: 109px;
}

.titleFutureMonth {
  text-align: right !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 13px;
  line-height: 13px;
  color: #485359 !important ;
  background: #c3f0fa !important;
  width: 109px;
}

.total {
  text-align: center !important;
  font-weight: 400;
  color: #485359 !important;
}

.ant-table-tbody .recognized {
  text-align: left !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 13px;
  line-height: 13px;
  color: #485359 !important ;
}

.ant-table-thead .recognized {
  text-align: center;
  color: #0c0e10;
}

.ant-table-thead .thisMonthRecognized {
  text-align: center;
  color: #0c0e10;
  text-transform: uppercase;
}

.ant-table-thead .nextMonthRecognized {
  text-align: center;
  color: #0c0e10;
  text-transform: uppercase;
}

.ant-table-thead .futureMonthRecognized {
  text-align: center;
  color: #0c0e10;
  text-transform: uppercase;
}

.ant-table-tbody .thisMonthRecognized {
  text-align: right;
  font-weight: normal;
}

.ant-table-tbody .nextMonthRecognized {
  text-align: right;
  font-weight: normal;
}

.ant-table-tbody .futureMonthRecognized {
  text-align: right;
  font-weight: normal;
}

.ant-table-tbody .titles {
  font-weight: bold;
  font-size: 12px;
  color: #485359;
}
#showColor {
  display: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.showVariancedetailThisMonth:hover ~ #showColor {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
.showVariancedetailNextMonth:hover ~ #showColor {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
.showVariancedetailFutureMonth:hover ~ #showColor {
  border: black;
  padding: 5px 5px 5px 5px;
  display: block;
  z-index: 100;
  background: rgb(87, 78, 78);
  right: 45px;
  margin: 15px;
  width: 150px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}

.cloneButton {
  float: "right";
  border-radius: 4px;
  color: #fff !important;
  border: none;
  font-size: 16px;
  height: 40px;
  background: #5c67e5 !important;
  width:220px;
}

.cardClone{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.buttonStyle{
  border-radius: 4px;
  color: #fff !important;
  background: #5c67e5 !important;
}

.monthOptions{
  margin-bottom: 15px;
}
.cloneToText{
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
}
