@import "~antd/dist/antd.css";
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text-end {
  text-align: right;
}

.attachment {
  margin-right: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-dropdown-trigger.ant-dropdown-link {
  width: fit-content;
  float: right;
  padding-left: 5px;
  span {
    font-size: 15px !important;
  }
}
.scrollabarChange {
  .ant-table-cell {
    padding: 10px;
  }
}
.exportTableButton {
  background: #1CD6CE;
  color: #7bcfa5;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 10px 0px 25px;
  .exportOultineIcon {
    font-size: 25px;
    color: white;
    transform: rotate(-90deg);
    &:hover {
      color: grey;
    }
  }
}
.mb-5 {
  margin-bottom: 5px;
}
.customDropDown {
  p {
    margin-bottom: 0;
  }
}
.dropDownOut {
  text-align: right;
  @media (max-width: 767px) {
    text-align: center;
    .ant-dropdown-trigger.ant-dropdown-link {
      width: auto;
      float: none;
      padding-left: 0;
      text-align: center;
    }
  }
}
.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
  .cancel--btn {
    background: #fff !important;
    color: #1e2225 !important;
    border: 1px solid #1e2225 !important;
  }
}
.input--field {
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  height: 40px !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-top: 3px !important;
  margin-bottom: 6px !important;
}
.textarea-input {
  height: 100px !important;
  font-size: 16px !important;
  margin-top: 3px !important;
  margin-bottom: 6px !important;
  border: 1px solid #918888 !important;
  border-radius: 4px !important;
  padding: 8px !important;
}

@media (max-width: 767px) {
  .ant-layout-header {
    height: auto !important;
  }
  .main-content-container {
    margin: 140px 5px 24px 5px !important;
    padding: 15px !important;
  }
  .basic-info p {
    word-break: break-all;
  }
  .tableOut {
    width: 100%;
    margin-top: 20px;
    overflow-x: scroll;
    background: #fff;
    .ant-table-cell {
      word-break: break-all;
    }
    &.noWordBreak {
      .ant-table-cell {
        word-break: normal;
      }
    }
  }
  .tabsOut .tab-contents .btns-out a {
    width: 100%;
    display: block;
    text-align: center;
    margin: 10px 0 15px 0 !important;
  }
  .tabsOut .tab-contents h5 {
    padding: 0;
  }
  .addUserBtn {
    .ant-btn-primary.primary-btn {
      width: 100%;
    }
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  .ant-drawer-content-wrapper {
    width: 100%;
  }
  .ant-layout-sider {
    display: none;
  }
  .toggle1 .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    margin-left: 0 !important;
  }
  .mobileNavOut {
    display: block;
    position: fixed;
    bottom: 0;
    background: #485359;
    width: 100%;
    height: 79px;
    z-index: 99;
    .ant-menu-inline.ant-menu-root .ant-menu-item,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      display: block;
      align-items: unset;
      color: #fff;
      padding: 15px 0 !important;
    }
    .ant-menu {
      background: #485359;
      .toggleIn {
        .menu-mob-Inner {
          padding-bottom: 10px;
          text-align: center;
          max-width: 180px;
          margin-top: 0;
          overflow: hidden;
          border-bottom: 1px dashed #fff;
          h4 {
            margin: 10px 0 5px;
            color: #fff;
            font-size: 18px;
            // border-bottom: 1px dashed #fff;
            overflow: hidden;
            padding: 10px 0 20px;
            display: inline-block;
            width: 100%;
          }
          .ant-menu-item {
            padding: 5px 10px !important;
            // &:last-child {
            //   border-bottom: 1px dashed #fff;
            // }
          }
        }
      }
      .ant-menu-item {
        float: left;
        width: 33%;
        text-align: center;
        height: auto;
        line-height: normal;
        .ant-menu-title-content {
          display: block;
          overflow: unset !important;
          text-overflow: unset !important;
          margin: 5px 0 0 0;
          a {
            color: #fff;
          }
        }
        .anticon {
          font-size: 24px;
        }
      }
    }
  }
  .triggerBtn {
    display: none;
  }
  .years {
    padding: 0 20px !important;
    .ant-select {
      width: 100%;
    }
  }
  .ant-layout-header.header h6 {
    margin: 0 !important;
  }
  .ant-layout-header.header h1 {
    margin: 0 !important;
    text-align: center;
  }
  .addProjectBtn,
  .addUserBtn {
    width: 100%;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
  }
}
@media (min-width: 768px) {
  .mobileNavOut {
    display: none;
  }
}
.toggleOut {
  position: relative;
  .toggleIn {
    position: absolute;
    bottom: -17px;
    right: 0;
    background: #485359;
    padding: 6px;
    transform: translate(0px, -94px);
  }
}
.innerToggleOut {
  &.ant-menu-inline {
    border-right: none !important;
  }
  .ant-menu-item {
    width: 100% !important;
  }
}
.capacitySalesOut {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: flex-end;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}
.earnedExpandedOut {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: flex-end;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}
// html {
//   --antd-wave-shadow-color: transparent !important;
// }

@media all and (max-width: 1400px) {
  .overflowEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.ant-dropdown-link{
  margin: 0 !important;
}