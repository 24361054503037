.add_user_form {
  .users--field {
    margin-top: 5px;
    border: 1px solid #b9b7bd;
    font-size: 16px;
    background-color: #fff;
  }
}

.AddUserBUSelect {
  width: auto;
  position: relative;
  font-size: 14px;
}
