.DropTableContainer {
  margin-top: 30px;
  margin-left: 90px;
}
.label {
  margin-left: 30%;
  margin-top: 3%;
}

.cancel--btn {
  background-color: white;
  font-size: 16px;
  height: 40px;
  border-radius: 4px;
  color: black;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.spin-Details {
  position: absolute;
  top: 60%;
  left: 50%;
}

.card-Details {
  position: absolute;
  top: 40%;
  left: 40%;
  background: white;
}
