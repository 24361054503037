.ant-drawer.ant-drawer-right.add_creditNote {
  .css-2b097c-container {
    margin-top: 10px;
  }
  .css-yk16xz-control {
    height: 40px;
    border-radius: 2px;
  }
  .input--textarea {
    height: 120px;
  }
}

.button-container {
  .ant-btn-primary.primary-btn {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .ant-drawer.ant-drawer-right.add_project {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.select--category {
  width: 100%;
  height: 50px;
}
.ant-select-selection-search {
  height: 10px;
}
.input--field {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}

.input_container {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 10px;
}

.edit-image-button-credit {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #1e2225;
}
.edit-image-button-expense {
  margin-top: 8px;
  height: 40px;
  width: 50%;
  border-radius: 2px;
  border: 1px solid #1e2225;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 10px;
}

.totalpercent {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
}

.progress-label {
  margin-bottom: 3px;
  font-size: medium;
}

.progress-bar-container {
  width: 160px;
  height: 2px;
}

.toatlpercentcontainer {
  display: flex;
  flex-direction: column;
}
.AdjustedPending {
  display: flex;
  flex-direction: row;
  font-size: medium;
  font-weight: bold;
}

.Fontchanges {
  color: #5c67e5;
  font-weight: bold;
}
.select-dropdown {
  width: 300px;
}

.expand-button {
  margin-right: 8px;
  font-size: 20px;
  cursor: pointer;
}

.purchase-order-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.expanded-details {
  width: 90%;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #e8e8e8;
  background-color: #f9f9f9;
}

.expanded-details span {
  display: block;
  margin-bottom: 4px;
}

.details-row {
  display: flex;
  justify-content: space-between;
}
.light-font-poexpansion {
  font-weight: lighter;
  color: #8595a5;
}

.totalText {
  font-weight: bolder;
  font-size: larger;
}

.date-picker {
  width: 150px;
}
.date-picker-input {
  width: 100%;
  height: 53px;
  border: 1px solid #918888 !important ;
  border-radius: 4px !important;
}

.vendor_name_input {
  width: 100%;
  height: 35px;
}
.input-expense {
  border: 1px solid #918888;
  border-radius: 4px;
  padding: 8px;
}
.vendor-input {
  .ant-select {
    .ant-select-selector {
      height: 50px !important;
      width: 452px !important;
    }
  }
}

.vendor-input {
  .ant-select {
    .ant-select-selection-placeholder {
      padding-top: 10px !important;
    }
  }
}

.amount_input{
   border: 1px solid #918888 !important;
}

.totalCreditNote{
  margin-top: 15px;
}