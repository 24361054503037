.Input_salesPercentage {
  height: 50%;
  margin-top: 2%;
}
.pdf-placeholder-image {
  height: 4rem;
  width: 66px;
}
.attachment {
  margin-bottom: 1rem;
}
.xlsx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.docx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.column-with-border {
  padding-right: 16px;
}
.display_project_customer_relation {
  min-height: 120px;
}
.sales-primary-btn {
  background-color: #5c67e5;
  margin-top: 2%;
  width: 16%;
  border-radius: 3px;
}

.update-button {
  width: 85%;
  height: 83%;
  margin: 6px 20px 0px 10px;
}

.text-Note {
  text-align: center;
}

.update-button-sales {
  margin-right: 10px;
}
