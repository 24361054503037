
.select--field {
  height: 45px !important;
  font-size: 16px !important;
  width: 150% !important;
  background-color: #fff;
  flex: 1;
}
.pendingStatus {
  color: #ff7a37;
}
.sentStatus {
  color: #00bf79;
}
.textValue {
  font-size: medium;
}
.card_Bg {
  height: 2ch;
  width: 100%;
  padding-bottom: 20ch;
}
.BuFilter {
  appearance: none;
  outline: 0;
  height: 100%;
  border: 0.5px;
  border-radius: 3px;
  position: relative;
  display: block;
  width: 50em;
  line-height: 0.25;
  padding-bottom: 10px;
}
.MonthOptn {
  appearance: none;
  outline: 0;
  width: 100%;
  height: 100%;
  border: 0.5px;
  border-radius: 3px;
  position: relative;
  display: block;
  line-height: 0.25;
  padding-bottom: 10px;
}

.buList {
  margin: 20px 0 0 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    margin: 0 20px 0 0;
    display: inline-block;
    width: 17%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      position: relative;
      display: block !important;
      .ant-checkbox {
        display: block;
        position: static;
        input[type="checkbox"] {
          height: 40px;
        }
        + span {
          padding-right: 0;
          padding-left: 0;
        }
        .ant-checkbox-inner {
          width: 100% !important;
          display: block;
          position: static;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #8595a5;
          background-color: transparent;
          &::after {
            border: none;
            transform: none;
          }
        }
      }
      .ant-checkbox-checked {
        &::after {
          border: none;
        }
        .ant-checkbox-inner {
          border: none;
          background: #5c67e5;
          color: #fff;
        }
        + span {
          color: #fff;
        }
      }
      > span {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        line-height: 43px;
        text-align: center;
        font-style: normal;
        color: #8595a5;
      }
    }
  }
}
