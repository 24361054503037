.basic-info {
  .BasinInfoHeader {
    margin-bottom: 20px;
    .imgColumn {
      text-align: right;
    }
  }
}

.boxStyle {
  border: 1px solid #989898;
  border-radius: 4px;
  height: 62px;
  width: 62px;
  display: grid;
  place-items: center;
}
.pdf-placeholder-image {
  height: 4rem;
  width: 66px;
}
.attachment {
  margin-bottom: 1rem;
}
.xlsx-placeholder-image {
  height: 4rem;
  width: 66px;
}
.attachment-img {
  border: 1px solid #989898;
  border-radius: 4px;
}
.docx-placeholder-image {
  height: 4rem;
  width: 66px;
}
