

.modal-header {
    display: flex;
    align-items: center;
  }

  

  .modal-header span {
    margin-left: 0px;
    font-weight: bold; 
  }
  
  .edit-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px; 
  }
  
  .bold {
    font-weight: bold; 
  }

.date-picker-container {
    padding-top: 20px;
    padding-bottom: 30px;
  
  
    .custom-date-picker {
      width: 100%;
    }
  }
  
  .ant-modal-header {
    border-bottom: 2px solid  #5C67E5;
    ;
  }

  .final-status-section{
    padding-top: 20px;
  }


.q-heading{
    
    font-weight: bold;
    font-size: 18PX;
}

.question{
    padding-bottom: 30px;
    margin: 0;
}

.rating-container {
    position: relative;
    flex-basis: 20%;
    margin-top: 10px;
    
  }
  
  .questionnaire-label {
    font-weight: bold;
    padding-bottom: 15px;
    font-size: 14px;
   
    flex-basis: calc(80% - 20px);
    
  }

  .questionnaire {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;

    $textareaHeight: 100px;
    .description {
        height: $textareaHeight;
        width: 100%;
      }
  }

  .rating-dropdown {
    
    width: 123%;
    position: absolute;
    bottom:-5px;
    z-index: 1;
  }


  .final-status-section {
    label {
      font-weight: bold;
    }
  }
  
  .rating-section {
    .input-with-border {
      border: 1px solid rgb(206, 204, 204);
      display: flex;
      align-items: center;
      padding: 5px;
     
  
      .color-box-input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: red;
        margin-right: 5px;
      }
  
      .rating-text-input {
        border: none;
        width: 100%;
      }
    }
  }
  
  .button-container {
    
    padding-left: 40px;
    
  }

  .uploaded-files-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .uploaded-file-entry {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-container {
    flex: 1;
  }
  
  .delete-button-container {
    width: 100%;
    text-align: center;
  }
  
  