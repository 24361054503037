.tabsOut {
  margin: 0;
  padding: 0;
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #fff !important;
      text-shadow: none;
      border: none;
      background: #5c67e5;
    }
  }
  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #8595a5;
    border-radius: 60px;
    padding: 7px 20px;
    color: #8595a5;
  }
  .tab-contents {
    margin: 0;
    padding: 0;
    h5 {
      font-weight: 350;
      font-size: 22px;
      color: #1e2225;
    }
    .btns-out {
      margin: 0;
      padding: 0;
      a {
        font-weight: 350;
        font-size: 14px;
        border: 1px solid #8595a5;
        color: #8595a5;
        border-radius: 60px;
        padding: 8px 15px;
        margin: 0 0 0 15px;
        &.active {
          border: none;
          color: #fff;
          background: #5c67e5;
          border-radius: 60px;
        }
      }
    }
    .sold-amount {
      h1 {
        font-weight: 350;
        font-size: 32px;
        color: #5c67e5;
        small {
          font-size: 16px;
          color: #1e2225;
          margin: 0 0 0 7px;
          @media (max-width: 767px) {
            display: block;
          }
        }
      }
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
}
.tab-contenets-top {
  .tabsParent {
    margin-top: 10px;
    & > .ant-tabs-nav {
      border-bottom: 1px solid #d0dae6;
      .ant-tabs-tab {
        color: #8595a5;
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #5c67e5;
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      background: #5c67e5;
    }
  }
}
.years {
  margin: 0;
  padding: 0;
  .ant-select-selector {
    border: 1px solid #adbdcc !important;
    border-radius: 4px !important;
  }
}


.buList {
  margin: 20px 0 0 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    margin: 0 20px 0 0;
    display: inline-block;
    width: 17%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      position: relative;
      display: block !important;
      .ant-checkbox {
        display: block;
        position: static;
        input[type="checkbox"] {
          height: 40px;
        }
        + span {
          padding-right: 0;
          padding-left: 0;
        }
        .ant-checkbox-inner {
          width: 100% !important;
          display: block;
          position: static;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #8595a5;
          background-color: transparent;
          &::after {
            border: none;
            transform: none;
          }
        }
      }
      .ant-checkbox-checked {
        &::after {
          border: none;
        }
        .ant-checkbox-inner {
          border: none;
          background: #5c67e5;
          color: #fff;
        }
        + span {
          color: #fff;
        }
      }
      > span {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        line-height: 43px;
        text-align: center;
        font-style: normal;
        color: #8595a5;
      }
    }

    // &:nth-child(1) {
    //   width: 135px;
    //   @media (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
    // &:nth-child(2) {
    //   width: 135px;
    //   @media (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
    // &:nth-child(3) {
    //   width: 135px;
    //   @media (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
    // &:nth-child(4) {
    //   width: 135px;
    //   @media (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
    // &:nth-child(5) {
    //   width: 135px;
    //   @media (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
  }
}
.capacitySalesOut {
  margin: -100px 0 0 0;
  @media (max-width: 767px) {
    margin: 0 0 0 0;
  }
}

.textHeadingBu {
  font-size: 16px;
  font-weight: lighter;
}

.textValueBu {
  font-size: 16px;
}

.textAlignRight {
  text-align: right;
}

.soldValueColor {
  color: #5c67e5 !important;
}

.pendingValueColor {
  color: orange !important;
}