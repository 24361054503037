.talentTable {
  
  td {
    font-weight: normal;
    .nameTable {
      font-weight: normal;
    }
  }
}

.activeStatus {
  font-weight: normal;
  color: #00bf79;
}

.inActivestatus {
  font-weight: normal;
  color: #e62254;
}

.totalLabel {
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

.addTalentBtn {
  display: inline-block;
}

@media (max-width: 767px) {
  .addTalentBtn {
    .ant-btn-primary.primary-btn {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}
