.textarea--field {
  width: 90% !important;
  font-size: 16px !important;
}

.AvailableHours-field {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 4px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  pointer-events: none;
  color: #bfbfbf;
  height: 40px;
}
