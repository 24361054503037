.circles {
  padding-left: 80px;
}

.labels {
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 35px;
  font-size: 14px;
  color: #1e2225;
}
