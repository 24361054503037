.update-button {
  width: 60%;
  margin: 20px 20px 0 20px;
  background-color: #5c67e5;
}

.select-option {
  height: 70%;
  margin-left: 15px;
  text-align: left;
}

.ant-modal-body {
  padding: 40px;
  
  p {
    margin-top: 5px;
  }
}
.ant-modal-footer {
  padding: 0 0 30px 0;
  text-align: center;
  border-top: 0px !important;
}
