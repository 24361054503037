.loader_Card {
  position: absolute !important;
  top: 39% !important;
  left: 43% !important;
  background: white !important;
  width: 20%;
}

.loader_Spin {
  position: absolute !important;
  top: 60% !important;
  left: 45% !important;
}
