.EmailLable {
  margin-bottom: 10px;
}

.edit_user_form {
  input {
    margin-top: 5px;
    border: 1px solid #b9b7bd;
    font-size: 16px;
    background-color: #fff;
  }
}

.EditBuSelect.css-2b097c-container {
  height: 100px;
}
