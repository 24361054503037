.ant-design.form-group__input {
  box-shadow: 0px 4px 20px rgba(72, 83, 89, 0.12);
  height: 50px;
  font-size: 16px;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  // width: 352px;
  border: 1px solid #adbdcc;
  font-size: 16px;
}
label {
  font-size: 16px;
  // margin-bottom: 10px;
}

.css-yk16xz-control {
  height: 100%;
  background-color: #fff;
  border: 1px solid #b9b7bd;
}

.css-2b097c-container {
  height: 100%;
  .css-yk16xz-control {
    height: 100%;
  }
  .css-1pahdxg-control {
    height: 100%;
  }
  margin-top: 5px;
  .css-1wa3eu0-placeholder {
    font-size: 16px;
    color: hsl(0, 0%, 75%);
  }
}
