.table-card.revenue-table {
  .table-bold-rows {
    .ant-table-cell {
      font-weight: bold;
    }
  }
  margin-bottom: 10px;
}

.talentTable {
  width: 100%;
}

.PresentStatus {
  background-color: #b2bbf7;
}

.FutureStatus {
  background-color: #f8e0b1;
}
.number {
  text-align: right;
}
.total {
  text-align: right !important;
  font-weight: bold !important;
}
.title {
  font-weight: bold !important;
}
.onClick {
  color: #5389f8 !important;
}

.buList {
  margin: 20px 0 0 0;
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    margin: 0 20px 0 0;
    display: inline-block;
    width: 17%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      position: relative;
      display: block !important;
      .ant-checkbox {
        display: block;
        position: static;
        input[type="checkbox"] {
          height: 40px;
        }
        + span {
          padding-right: 0;
          padding-left: 0;
        }
        .ant-checkbox-inner {
          width: 100% !important;
          display: block;
          position: static;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #8595a5;
          background-color: transparent;
          &::after {
            border: none;
            transform: none;
          }
        }
      }
      .ant-checkbox-checked {
        &::after {
          border: none;
        }
        .ant-checkbox-inner {
          border: none;
          background: #5c67e5;
          color: #fff;
        }
        + span {
          color: #fff;
        }
      }
      > span {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        line-height: 43px;
        text-align: center;
        font-style: normal;
        color: #8595a5;
      }
    }
  }
}
