.textAlignCurrency {
  text-align: right;
}

.sold-amount {
  h1 {
    font-weight: 350;
    font-size: 32px;
    color: #5c67e5;
    small {
      font-size: 16px;
      color: #1e2225;
      margin: 0 0 0 7px;
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}

.textAlignRight {
  text-align: right;
}

.textHeading {
  font-size: x-large;
  font-weight: lighter;
}
.textValue {
  font-size: x-large;
}

.pr20 {
  padding-right: 20px;
}

#buInfoSales {
  display: none;
}
.showBuDetailSales:hover ~ #buInfoSales {
  border: #666 2px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 100;
  background: #e3e3e3;
  left: 0px;
  margin: 15px;
  width: 300px;
  position: absolute;
  top: 15px;
  text-decoration: none;
}
