.displayDate {
  p {
    font-size: 22px;
  }
}

.performanceTable {
  
  tbody > tr > :nth-child(2) {
    text-align: right;
  }
}
.performanceCardTop {
  margin-right: 20px;
}

.bulgeGraph {
  height: 100%;
}
.attritionProgress {
  height: 100%;
}
.skillBasedGraph {
  margin-right: 20px;
}
.bulgeGraphCard {
  padding-left: 0px !important;
}
.teamSizeGraphCard {
  padding-right: 20px !important;
}
.input--field.TalentDatePicker {
  margin-top: 0px !important;
  width: 100% !important;
}
.skillsFilter {
  .css-1r4vtzz {
    width: 100%;
  }
}
.departmentsFilter-snapshot {
  .css-1r4vtzz {
    width: 100%;
  }
}
