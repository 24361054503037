.main-div {
  margin-top: 1rem;
  background-color:#fff;
  padding: 1rem;
  border: 1px dashed #73c8ee;
}

.select-before {
  width: 90px;
}

[data-theme="compact"] .select-before {
  width: 71px;
}

.mb--10 {
  margin-bottom: 1rem !important;
}
