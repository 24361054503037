.expense-item {
  margin-bottom: 30px;
  background-color: #f6f7fb;
  border-radius: 4px;
}

.expense-text {
  font-weight: 500px;
  font-size: 22px;
  padding-left: 20px;
}

.total-adjusted-text{
  padding-top: 30px;
  padding-left: 20px;
  font-weight:bold;
  font-size:18px,
}

.heading-text {
  font-size: 17px;
  color: #8595a5;
  font-weight: 400px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.details-font {
  font-size: 14px;
  font-weight: 500px;
  padding-left: 20px;
  padding-right: 20px;
}

.no-expense-div {
  display: grid;
  place-items: center;
  margin-top: 5rem;
  color: #00000073;
  font-size: 20px;
}

.no-expense-image{
  font-size: 100px;
  color:#00000073;
}

