.edit_po {
  img {
    float: right;
  }
  label {
    margin-top: 20px;
  }
  .po-form {
    display: inline-block;
    margin: 10px 0 0 20px;
    .input-fields {
      margin-top: 10px;
    }
    .input--field {
      width: 90% !important;
      // display: inline-block;
      // float: right;
    }
    .edit_button_div {
      display: flex;
      align-items: center;
      justify-content: center;
      .update-button {
        width: 30%;
        margin: 20px 20px 0 20px;
        background-color: #5c67e5;
      }
      .cancel-button {
        margin: 20px 20px 0 20px;
        // background-color: #5c67e5;
        background: #fff;
        color: #1e2225;
        border: 1px solid #1e2225;
      }
      .delete-button {
        margin: 20px 20px 0 20px;
        color: #ef4646;
        border: 1px solid #ef4646;
        background: #fff;
      }
    }
  }
}

.edit-image-button {
  margin-top: 8px;
  margin-left: 15px;
  height: 30px;
  width: 75%;
}

.display_project {
  margin-bottom: 35px;
  margin-top: 5px;
  padding-inline-end: 30px;
}

.boxStyle {
  border: 1px solid #989898;
  border-radius: 4px;
  height: 62px;
  width: 62px;
  display: grid;
  place-items: center;
}
