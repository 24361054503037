.changePassword {
  label {
    font-size: 16px;
  }
  .ant-row {
    margin-bottom: 15px;
  }
  .PasswordBtn {
    .passwordAdd-btn {
      margin-right: 15px;
    }
    .cancel--btn {
      background: #fff;
      color: #1e2225;
      border: 1px solid #1e2225;
    }
  }
}

.password--field {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  height: 40px;
  font-size: 16px;
  // width: 100%;
  // margin-top: 3px;
  // margin-bottom: 6px;
  width: 352px;
}
